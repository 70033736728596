<template>
  <div class="product">
    <router-link
      :to="productPath"
      class="product-link"
      :style="{ cursor: productPath ? 'pointer' : 'default' }"
    >
      <div class="product-img">
        <img :src="productImageSrc" :alt="productName" />
      </div>
      <VueText class="product-name" weightLevel="3" sizeLevel="5" color="grey-40">{{
        productName
      }}</VueText>
    </router-link>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
export default {
  name: 'BrandProduct',
  components: {
    VueText,
  },
  props: {
    productImageSrc: {
      type: String,
    },
    productName: {
      type: String,
    },
    productPath: {
      type: String,
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  &-img {
    width: 100%;
    height: 100px;
    min-height: 150px;
    padding: palette-space-level('15');
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('2');

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    padding: palette-space-level('10') 0;
    text-align: center;
  }
}
</style>
